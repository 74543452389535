<template>
  <div class="page">
    <Header :loginState="loginState" v-if="!isClient"/>
    <div :class="isClient?'account-page-app':'account-page'">
      <left-menu v-if="!isClient"/>
      <div class="account-center">
        <div class="center-box account-information">
          <transaction-nav v-if="!isClient"/>
          <div class="title-box">
            <div class="cfx"></div>
            <p>{{$t('accountCenter.depositTite1')}}</p>
          </div>
          <div class="account-data deposit-data-box">
            <div class="deposit-fl">
              <div class="sum-box">
                <div class="sum-list">
                  <p class="title">{{$t('accountCenter.depositTite2')}}</p>
                  <div class="input-main">
                    <input type="text" v-model="money" @input="limitInput($event,'value')">
                    <div class="unit">
                      USD
                    </div>
                  </div>              
                </div>
                <p class="be-to">=</p>
                <div class="sum-list">
                  <p class="title">{{$t('accountCenter.depositTite3')}}</p>
                  <div class="input-main">
                    <input type="text" :value="(money * currentPayment.exchangeRate).toFixed(2)" disabled>
                    <div class="unit">
                      {{currentPayment.paymentCurrency}}
                    </div>
                  </div>   
                </div>
                <!-- <p class="err-text" v-if="money < currentPayment.inMinimum || money > currentPayment.inMaximum">
                  <img src="@/assets/images/register/dx-icon.png" alt="" />
                  <span v-if="money < currentPayment.inMinimum || money > currentPayment.inMaximum">{{$t('accountCenter.dataStatus8')}}：{{currentPayment.inMinimum}}（USD），{{$t('accountCenter.dataStatus9')}}：{{currentPayment.inMaximum}}（USD）</span> <br/>
                </p> -->
                
              </div> 
              <p class="sum-err-text" v-if="money < currentPayment.inMinimum || money > currentPayment.inMaximum">
                <img src="@/assets/images/h5/ban.png" alt="">
                <span>{{$t('accountCenter.dataStatus8')}}：{{currentPayment.inMinimum}}（USD），{{$t('accountCenter.dataStatus9')}}：{{currentPayment.inMaximum}}（USD）</span>
              </p>
        
              <div class="app-page uc-de-page">
                <p v-if="paymentSettings !=''" class="title2">{{$t('accountCenter.depositTite4')}}</p>
                <div class="app-deposit-from" :class="styleState">
                  <!-- <p class="title top-tlt"><span>{{$t('accountCenter.depositTite2')}}（USD）</span><a @click="guideBtn" class="guide-btn" href="javascript:;">{{$t('accountCenter.depositTite11')}}</a></p>
                  <div class="input-app">
                    <input v-model="money" type="number">
                    <img @click="emptyMoney" src="@/assets/images/h5/del.png" class="del-icon" alt="" />
                  </div>
                  <div class="unit-p"><p>{{$t('accountCenter.depositTite12')}}（{{currentPayment.paymentCurrency}}）：</p> <span>{{(money * currentPayment.exchangeRate).toFixed(2)}}</span></div>
                  <p v-if="money < currentPayment.inMinimum || money > currentPayment.inMaximum" class="err-box">
                    <img src="@/assets/images/h5/ban.png" alt="">
                    <span>{{$t('accountCenter.dataStatus8')}}：{{currentPayment.inMinimum}}（USD），{{$t('accountCenter.dataStatus9')}}：{{currentPayment.inMaximum}}（USD）</span>
                  </p> -->
                  <div v-if="paymentSettings !=''" class="amount-box-app">
                    <div class="amount-list-app " :class="money==item.money ? 'active':''" v-for="(item,index) in paymentSettings" :key="index" @click="selectPaymentSetting(item)">
                      <p class="dep-amount"><span>$</span>{{item.money}}</p>
                      <p class="give-amount">{{item.descKey}}<span v-if="item.giveMoney">$</span>{{ item.giveMoney }}</p>
                    </div>
                  </div>
                  <p v-if="paymentSettings !=''" class="p-sm">{{$t('accountCenter.depositTite13')}}</p>  
                  <p class="title app-rj-fs">{{$t('accountCenter.depositTite15')}}</p> 
                  <div class="app-rj-box">
                    <div class="app-rj-menu">
                      <div v-for="(item,index) in paymentChannelsList" :key="index" @click="selectGoldChanne(item)" class="app-rj-channel">
                        <img class="app-rj-icon" :src="item.payLogo" alt="">
                        <div class="app-rj-data">
                          <div class="app-rj-a">
                            <div class="app-rj-l">
                              <p class="app-rj-name"><span>{{ item.name }}</span><img v-if="index == 0" class="hot-img" src="@/assets/images/h5/hot.png" alt=""></p>
                              <p class="app-rj-xe">{{$t('accountCenter.singleLimit')}}{{ item.inMaximum }}{{$t('accountCenter.dollar')}}</p>
                            </div>
                            <img v-if="selectState == item.type || (index == 0 && selectIndex == 0)" class="app-rj-dx" :src="require('@/assets/images/h5/choose'+styleimg+'.png')" alt="">
                            <img v-else class="app-rj-dx" src="@/assets/images/h5/null.png" alt=""> 
                          </div>
                          <div v-if="item.type == 'szfucvs'">
                            <div v-if="szfucvsState || index == 0" class="app-rj-q">
                              <div v-for="(items,indexs) in bankList" :key="indexs" @click.stop="getBankvalue(item,items)" class="app-rj-q-list">                
                                <img v-if="selectState2 == items.value" class="app-rj-dx" :src="require('@/assets/images/h5/choose'+styleimg+'.png')" alt="">
                                <img v-else class="app-rj-dx" src="@/assets/images/h5/null.png" alt="">
                                <p class="app-rj-name2">{{ items.name }}</p>
                              </div>
                            </div> 
                          </div>          
                        </div>         
                      </div>
                      <div v-if="paymentChannelsList2 !='' && moreState" @click="goldChanneShow" class="more-box">
                        <p>{{$t('accountCenter.depositTite14')}}</p>
                        <img src="@/assets/images/h5/unfold.png" alt="">
                      </div>
                      <div v-if="goldChanneState">
                        <div v-for="(item,index) in paymentChannelsList2" :key="index" @click="selectGoldChanne(item)" class="app-rj-channel">
                          <img class="app-rj-icon" :src="item.payLogo" alt="">
                          <div class="app-rj-data">
                            <div class="app-rj-a">
                              <div class="app-rj-l">
                                <p class="app-rj-name"><span>{{ item.name }}</span></p>
                                <p class="app-rj-xe">{{$t('accountCenter.singleLimit')}}{{ item.inMaximum }}{{$t('accountCenter.dollar')}}</p>
                              </div>
                              <img v-if="selectState == item.type" class="app-rj-dx" :src="require('@/assets/images/h5/choose'+styleimg+'.png')" alt="">
                              <img v-else class="app-rj-dx" src="@/assets/images/h5/null.png" alt=""> 
                            </div>
                            <div v-if="item.type == 'szfucvs'">
                              <div v-if="szfucvsState" class="app-rj-q">
                                <div v-for="(items,indexs) in bankList" :key="indexs" @click.stop="getBankvalue(item,items)" class="app-rj-q-list">                
                                  <img v-if="selectState2 == items.value" class="app-rj-dx" :src="require('@/assets/images/h5/choose'+styleimg+'.png')" alt="">
                                  <img v-else class="app-rj-dx" src="@/assets/images/h5/null.png" alt="">
                                  <p class="app-rj-name2">{{ items.name }}</p>
                                </div>
                              </div> 
                            </div>            
                          </div>         
                        </div>
                      </div>
                      
                    </div> 
                  </div>
                  <button @click="submitDepositData" class="submit-deposit">{{$t('accountCenter.subDeposit')}}</button>
                </div>
            
              </div>                      
            </div>
            <div class="deposit-fr">
              <p class="title">{{$t('accountCenter.depositTite6')}}</p>
              <div class="zhjs-box">
                  <div class="zhjs-data">
                    <div class="body-tlt">
                      <span>{{$t('accountCenter.accountType')}}</span><span>{{$t('accountCenter.miniAccount')}}</span><span>{{$t('accountCenter.standardAccount')}}</span><span>{{$t('accountCenter.highendAccount')}}</span>
                    </div>
                    <ul class="jl-cont">
                      <li><span>{{$t('accountCenter.accountTypeTitle1')}}</span><span>{{findUserInfo.miniAmount}}USD</span><span>1000USD</span><span>5000USD</span></li>
                      <li><span>{{$t('accountCenter.accountTypeTitle2')}}</span><span>1.4</span><span>0.8</span><span>0.7</span></li>
                    </ul>
                  </div>                 
                </div>
                <p class="title">{{$t('accountCenter.depositTite7')}}</p>
                <div class="zjaq-box">
                  <div class="zjaq-list">
                    <img class="rj-icon1" src="@/assets/images/transaction/rj-icon1.png" alt="">
                    <p>{{$t('accountCenter.fundSecurityList1')}}</p>
                  </div>
                  <div class="zjaq-list">
                    <img class="rj-icon2" src="@/assets/images/transaction/rj-icon2.png" alt="">
                    <p>{{$t('accountCenter.fundSecurityList2')}}</p>
                  </div>
                  <div class="zjaq-list">
                    <img class="rj-icon3" src="@/assets/images/transaction/rj-icon3.png" alt="">
                    <p>{{$t('accountCenter.fundSecurityList3')}}</p>
                  </div>
                </div>
                <p class="title">{{$t('accountCenter.depositTite8')}}</p>
                <div class="rjxz-box">
                  <div class="rj-list">
                  <p class="rj-title" @click="rjxzShowBtn(1)"><span>{{$t('accountCenter.depositTips1')}}</span>
                    <img v-if="(rjxzStatus == 1)" src="@/assets/images/transaction/xs-icon.png" alt="">
                    <img v-else src="@/assets/images/transaction/xx-icon.png" alt="">
                  </p>
                  <p class="rj-cont" v-show="(rjxzStatus == 1)" >{{$t('accountCenter.depositTips01')}}{{inMoney}}USD</p>
                </div>
                <div class="rj-list">
                  <p class="rj-title" @click="rjxzShowBtn(2)"><span>{{$t('accountCenter.depositTips2')}}</span>
                    <img v-if="(rjxzStatus == 2)" src="@/assets/images/transaction/xs-icon.png" alt="">
                    <img v-else src="@/assets/images/transaction/xx-icon.png" alt="">
                  </p>
                  <p class="rj-cont" v-show="(rjxzStatus == 2)" >{{$t('accountCenter.depositTips02')}}</p>
                </div>
                <div class="rj-list">
                  <p class="rj-title" @click="rjxzShowBtn(3)"><span>{{$t('accountCenter.depositTips3')}}</span>
                    <img v-if="(rjxzStatus == 3)" src="@/assets/images/transaction/xs-icon.png" alt="">
                    <img v-else src="@/assets/images/transaction/xx-icon.png" alt="">
                  </p>
                  <p class="rj-cont" v-show="(rjxzStatus == 3)" >{{$t('accountCenter.depositTips03')}}</p>
                </div>
                <div class="rj-list">
                  <p class="rj-title" @click="rjxzShowBtn(4)"><span>{{$t('accountCenter.depositTips4')}}</span>
                    <img v-if="(rjxzStatus == 4)" src="@/assets/images/transaction/xs-icon.png" alt="">
                    <img v-else src="@/assets/images/transaction/xx-icon.png" alt="">
                  </p>
                  <p class="rj-cont" v-show="(rjxzStatus == 4)" >{{$t('accountCenter.depositTips04')}}</p>
                </div>
                <div class="rj-list">
                  <p class="rj-title" @click="rjxzShowBtn(5)"><span>{{$t('accountCenter.depositTips10')}}</span>
                    <img v-if="(rjxzStatus == 5)" src="@/assets/images/transaction/xs-icon.png" alt="">
                    <img v-else src="@/assets/images/transaction/xx-icon.png" alt="">
                  </p>
                  <p class="rj-cont" v-show="(rjxzStatus == 5)" >{{$t('accountCenter.depositTips010')}}</p>
                </div>
                <div class="rj-list">
                  <p class="rj-title" @click="rjxzShowBtn(6)"><span>{{$t('accountCenter.depositTips11')}}</span>
                    <img v-if="(rjxzStatus == 6)" src="@/assets/images/transaction/xs-icon.png" alt="">
                    <img v-else src="@/assets/images/transaction/xx-icon.png" alt="">
                  </p>
                  <p class="rj-cont" v-show="(rjxzStatus == 6)" >{{$t('accountCenter.depositTips011')}}</p>
                </div>
                  <!-- <p>{{$t('accountCenter.depositTips1')}}{{inMoney}}USD;</p>
                  <p>{{$t('accountCenter.depositTips2')}}</p>
                  <p>{{$t('accountCenter.depositTips3')}}</p>
                  <p>{{$t('accountCenter.depositTips4')}}</p> -->
                  <!-- <p>{{$t('accountCenter.depositTips5')}}</p>  -->
                  <!-- 入金指南先隐藏 -->
                  <!-- <div class="hide-pc">
                     <a class="guide-btn" :href="depositGuidePc" target="_blank">{{$t('accountCenter.depositGuide')}}</a>
                  </div>                
                  <div class="hide-m">
                     <a class="guide-btn" :href="depositGuideM" target="_blank">{{$t('accountCenter.depositGuide')}}</a>
                  </div>                        -->
                </div>             
            </div>
          </div>
           
        </div>
      </div>
    </div>
    
    <!-- 加载提示 -->
    <div v-if="upLoadState" class="loading-box">
        <img :src="require('@/assets/images/h5/ld'+styleimg+'.png')" alt="">    
    </div>
    <!-- 提示框组件 -->
    <Dialog ref="myConfirm"  :isShowConfirm="isShowConfirm" @userBehavior="userBehaviorFun" />

    <!-- 信用卡入金通道弹窗 -->
    <div class="credit-Card-modal">
      <div class="mask" v-if="showModalCreditCard"></div>
      <div class="pop" v-if="showModalCreditCard">
        <div class="data-box">
          <div class="title-box"><p class="title">{{$t('accountCenter.creditCardText1')}}</p> </div> 
          <img @click="creditCardShow" class="close-btn" src="@/assets/images/register/close.png" alt="">      
          <div class="credit-Card-box">
            <div class="input-list"> 
                <div class="input-box">
                  <img class="cc-icon" src="@/assets/images/transaction/cc-icon.png" alt="" />
                  <input v-model="creditCardNumber" @blur="inputBlur(1)" class="input-c" type="number" :placeholder="$t('accountCenter.creditCardText2')" />
                </div>                             
                <p v-if="creditCardErr1" class="err-text">{{creditCardErrContent1}}</p>
            </div>
            <div class="input-list cc-box">
              <div class="input-l">
                <p class="title">{{$t('accountCenter.creditCardText4')}}</p>
                <div class="year-moth">
                  <!-- 月份 -->
                  <div class="c-channel-box">
                    <div @click.stop="getAllMonth" class="c-channel">    
                      <div class="c-channel-data">
                        <p class="name dian1">{{monthVal}}</p>
                        <input type="hidden" :value="monthVal" >
                      </div>
                      <img v-if="!monthState" class="xl-icon" src="@/assets/images/transaction/xx-icon.png" alt="">
                      <img v-else class="xl-icon" src="@/assets/images/transaction/xs-icon.png" alt="">
                    </div>
                    <div v-if="monthState" class="channer-menu scrollbarchange">
                      <div v-for="(item,index) in monthList" :key="index" @click="selectMonth(item)" class="c-channel">
                        <div class="c-channel-data">
                          <p class="name dian1">{{item}}</p>
                          <input type="hidden" :value="item">
                        </div>
                      </div>
                    
                    </div> 
                  </div>
                  <!-- 年份 -->
                  <div class="c-channel-box">
                    <div @click.stop="getAllYear" class="c-channel">    
                      <div class="c-channel-data">
                        <p class="name dian1">{{yearVal}}</p>
                        <input type="hidden" :value="yearVal" >
                      </div>
                      <img v-if="!yearState" class="xl-icon" src="@/assets/images/transaction/xx-icon.png" alt="">
                      <img v-else class="xl-icon" src="@/assets/images/transaction/xs-icon.png" alt="">
                    </div>
                    <div v-if="yearState" class="channer-menu scrollbarchange">
                      <div v-for="(item,index) in yearList" :key="index" @click="selectYear(item)" class="c-channel">
                        <div class="c-channel-data">
                          <p class="name dian1">{{item}}</p>
                          <input type="hidden" :value="item">
                        </div>
                      </div>
                    </div> 
                  </div>
                </div>
                                            
                <p v-if="creditCardErr2" class="err-text">{{creditCardErrContent2}}</p>
              </div> 
              <div class="input-r">
                <p class="title">{{$t('accountCenter.creditCardText5')}}</p>
                <div class="input-box">
                  <div class="wh-box">
                    <img class="wh-icon" src="@/assets/images/transaction/wh2-icon.png" alt="" />
                    <img class="hsw-icon" src="@/assets/images/transaction/hsw-icon.png" alt="">
                  </div>
                  <input v-model="creditCardCcv" @blur="inputBlur(2)" class="input-d input-cvv" type="text" placeholder="003"/>
                </div>                             
                <p v-if="creditCardErr3" class="err-text">{{creditCardErrContent3}}</p>
              </div>  
            </div>
            <div class="input-list cc-box">
              <div class="input-l">
                <p class="title">{{$t('accountCenter.creditCardText8')}}</p>
                <div class="input-box">
                  <input v-model="creditCardName" @blur="inputBlur(3)" class="input-d" type="text" :placeholder="$t('accountCenter.creditCardText9')"/>
                </div>                             
                <p v-if="creditCardErr4" class="err-text">{{creditCardErrContent4}}</p>
              </div>
              <div class="input-r">
                <p class="title">&nbsp;</p>
                <div class="input-box">
                  <input v-model="creditCardSurName" @blur="inputBlur(4)" class="input-d" type="text" :placeholder="$t('accountCenter.creditCardText10')"/>
                </div>                             
                <p v-if="creditCardErr5" class="err-text">{{creditCardErrContent5}}</p>
              </div>
            </div>
            <div class="ts-box"><p class="ts-text">{{$t('accountCenter.creditCardText13')}}</p></div>
            <button @click="submitCreditCard">{{$t('accountCenter.creditCardText14')}}</button>
          </div>                    
        </div>     
      </div>          
    </div>
    <!-- 第三方注册的用户未实名的入金选填国家弹窗 -->
    <div class="country-data-modal">
      <div class="mask" v-if="showModalCountry"></div>
      <div class="pop" v-if="showModalCountry">
        <div class="data-box">         
          <div class="country-box">
            <p class="title1">{{$t('perfectData.step3Title3')}}</p>
            <p class="title2">{{$t('perfectData.step3Title4')}}</p>
            <country-input :countryList="countryList" :errShowList="errShowList" @country="selectCountry"/> 
            <div class="ts-text"><img src="@/assets/images/register/xz-icon.png" alt=""><p>{{$t('perfectData.perfectDataTips')}}</p></div>                                     
            <button @click="submitCountry">{{$t('perfectData.nextStep')}}</button>    
          </div>                     
        </div>     
      </div>          
    </div>

    <!-- 支付渠道ID: szfuatm、thaipayp2p、vnldpayqr102、vnldpaypbank105这两条通道弹出提示框 -->
    <div class="tips-modal">
      <div class="mask" v-if="showModalTips"></div>
      <div class="pop" v-if="showModalTips">
        <div class="data-box">
          <img class="tips-icon" src="@/assets/images/transaction/tips-icon.png" alt=""> 
          <img @click="tipsShow" class="close-btn" src="@/assets/images/register/close.png" alt="">
          <p class="tips-text">{{$t('accountCenter.reminder')}}</p>      
          <div class="tips-box">
            <p class="p1">{{tipsContent[0]}}</p>
            <p class="p1">{{tipsContent[1]}}</p>
            <div class="chekbox-box">
              <input @click="checkboxState($event)" id="checkbox-input" type="checkbox" name="checkbox" value="1"/>
              <label for="checkbox-input">
                <p>{{$t('accountCenter.depositTips8')}}</p>
              </label>
            </div>
            <button @click="submitTipsDepositData">{{$t('accountCenter.depositTips9')}}</button>
          </div>                    
        </div>     
      </div>          
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header.vue";
import LeftMenu from '@/components/LeftMenu.vue';
import TransactionNav from '@/components/TransactionNav.vue';
import CountryInput from '@/components/CountryInput.vue';
import Dialog from '@/components/Dialog.vue';
import account from "@/api/account";
import { getCookie , setCookie} from '@/util/cookie';
import {Config,HEADER_USER_TOKEN} from "@/util/const";
import convert from 'xml-js'
import profit from "@/util/profitsdk";

export default {
  name: "Deposit",
  components: {
    Header,
    LeftMenu,
    TransactionNav,
    CountryInput,
    Dialog
  },
  data() {
    return {
      // userInfo:{},
      loginState:2,
      rjxzStatus:0,
      styleState:'',
      styleimg:'',
      selectState:'',
      selectState2:'',
      showModalTips:false,
      tipsState:'',
      tipsContent:[],
      showModalCountry:false,
      showModalCreditCard:false,
      creditCardErr1:false,
      creditCardErr2:false,
      creditCardErr3:false,
      creditCardErr4:false,
      creditCardErr5:false,
      creditCardErrContent1:'',
      creditCardErrContent2:'',
      creditCardErrContent3:'',
      creditCardErrContent4:'',
      creditCardErrContent5:'',
      monthState:false,
      yearState:false,
      currentMonth:'',
      monthVal:'MM',
      monthList:["01","02","03","04","05","06","07","08","09","10","11","12"],
      currentYear:'',
      yearVal:'YY',
      yearList:[],
      userEmail:'',
      creditCardNumber:'',
      creditCardCcv:'',
      creditCardName:'',
      creditCardSurName:'',
      countryCurrent:[],
      countryList: {
        countrys: [],
        countryActive:'',
      },
      errShowList: {
        errShow:false,
        errContent:'',
      },
      szfucvsState:false,
      moreState:true,
      selectIndex:0,
      isClient: profit.isClient,
      depositGuideUrl:"https://www."+Config.rootDomain+"/app/tw/deposit_step",
      // depositGuideM:'',
      goldChanneState:false,
      isShowConfirm:false,
      upLoadState : false,
      activityRemark:'',
      paymentChannels:[],
      paymentChannelsList:[],
      paymentChannelsList2:[],
      currentPayment:{
        name: '--',
        exchangeRate:'0',
        paymentCurrency:'',
      },
      // paymentCurrency:'',
      money:'',
      inMoney:'',
      paymentSettings:[],
      findUserInfo:[],
      bankList:[
        {name:this.$i18n.t('accountCenter.depositTite16'),value:'IBONS'},
        {name:this.$i18n.t('accountCenter.depositTite17'),value:'FAMILYS'},
        {name:this.$i18n.t('accountCenter.depositTite18'),value:'HILIFE'}
      ],
      bankId:'',
      currentBank:{name:this.$i18n.t('accountCenter.depositTite16'),value:'IBONS'},
    }
  },
  created() {
    // 查询用户基本资料
      const userToken = getCookie(HEADER_USER_TOKEN);
      if(!userToken){
        this.$router.push({ path: '/login' })
      }

      // 首次入金进入入金页面，判断ip是否与所选地区一致
      account.checkCountryByIp().then((res)=>{
        if(res.data.success == 1){
          this.showModalCountry = false;
        }else{
          this.showModalCountry = true;
        }
      }).catch(err=>{
        console.log(err.message);
      });

      // 获取app活动进入的入金参数
      var extra;
      var payMoney;
      const extraData = this.$route.query.extra;
      if(extraData){
        extra = JSON.parse(this.$route.query.extra);
        payMoney = extra.payMoney;
        const giveMoney = extra.giveMoney;
        console.log(giveMoney+"giveMoney");
        console.log(payMoney+"payMoney");
        if(giveMoney){
          this.activityRemark = giveMoney+'美元线下活动';
        }
      }

      // 根据马甲包ui颜色改变页面ui颜色
      const appId = this.$route.query.appId;
      switch(appId) {
          case "com.exchange6.app":
          case "com.fnsadw.ios":
          case "com.gotrade911.ios":
              console.log("blue-submit-deposit");
              this.styleState = "blue-app-deposit-from";
              this.styleimg = "-blue";
              break;
          default:
              this.styleState = "";
              this.styleimg = "";
      } 
      if (profit.isClient){
        profit.appEvent({eventName:'cm_deposit_commit_1'},{
          success(){},
          fail(){}
        })
      }
     let num =3;
      if (this._isMobile()) {
        num = 2;
      } else {
        num = 1;
      } 
     account.getPaymentChannels({platform:num})
      .then(res => {
        console.log(res)
        const paymentChannels = res.data.value;
        if(payMoney){
          this.money = payMoney;
        }else{
          this.money = paymentChannels[0].inNum;
        }
        this.inMoney = paymentChannels[0].inMinimum;
        this.paymentSettings = paymentChannels[0].activityList;
        for(var i= 0; i < paymentChannels.length; i++){
          if(i < 2 ){
            this.paymentChannelsList.push(paymentChannels[i]);
          } else {
            this.paymentChannelsList2.push(paymentChannels[i]);
          }
        }
        paymentChannels.forEach(channel =>{
          channel.payLogo = Config.images + '/' + channel.payLogo
        })
        this.paymentChannels = paymentChannels;
        this.currentPayment = paymentChannels[0]
      })

      // account.getPaymentSetting().then(res =>{
      //   this.paymentSettings = res.data.value;
      // });

      this.selectState2 = 'IBONS';

   account.findUserInfo().then(res => {
      var lang = "en";
      switch (getCookie('LOCALE')) {
        case 'th_TH':
          lang = "th";
          break;
        case 'zh_CN':
          lang = "ch";
          break;
        case 'zh_TW':
          lang = "tw";
          break;
        case 'en_US':
          lang = "en";
          break;
        case 'id_ID':
          lang = "id";
          break;
        case 'hi_IN':
          lang = "hi";
          break;
      }
      if(res.data.success == 1){
        this.findUserInfo = res.data.value; 
          if (res.data.value.identityResult != 1){
              if(res.data.value.depositLimit == 0){
                console.log("authentication");
                this.$router.push({ path: '/authentication' });
              }
          }          
          // if(res.data.value.countryId == "Other"){
          //     this.showModalCountry = true;
          // }else{
          //     this.showModalCountry = false;
          // }         
          if (res.data.value.countryId === 'Thailand'){
            this.depositGuideUrl = "https://www."+Config.rootDomain+"/app/"+lang+"/deposit_step_th";
          }else if(res.data.value.countryId === 'Indonesia'){
            this.depositGuideUrl = "https://www."+Config.rootDomain+"/app/"+lang+"/deposit_step_id";
          }else {
            this.depositGuideUrl = "https://www."+Config.rootDomain+"/app/"+lang+"/deposit_step";
          }       
      }else{
          console.log(res.data.message);
      }
    }).catch(err=>{
      console.log("findUserInfo",err.message);
    });
    // 获取邮箱注册的国家下拉列表数据
    account.getSupportCountry({"type":"all"}).then((res)=>{
     this.countryList.countrys = res.data.value
    //  this.countryList.countryActive = res.data.value[0].countryId
     let countryCurrent = this.countryList.countrys.filter(it => it.countryId == this.countryList.countryActive);
     this.countryCurrent = countryCurrent;
    }).catch(err=>{
      console.log(err);
    });
    
    // 调用初始化年份
    this.yearInit();
  },
  methods: {
      // 限制輸入两位小数
      limitInput(){
        if(this.selectState=='okpay'|| this.selectState == "CaresPayVisa"|| this.selectState == "CaresPayMaster"){
        this.money = String(this.money).replace(/\D/g, '');
      }
      let newMoney =String(this.money)
        // 第一步：转成字符串
        .replace(/[^\d^\.]+/g, "") // 第二步：把不是数字，不是小数点的过滤掉
        .replace(/^0+(\d)/, "$2") // 第三步：第一位0开头，0后面为数字，则过滤掉，取后面的数字
        // .replace(/^\./, "0.") // 第四步：如果输入的第一位为小数点，则替换成 0. 实现自动补全
        .match(/^\d*(\.?\d{0,2})/g)[0] || ""; // 第五步：最终匹配得到结果 以数字开头，只有一个小数点，而且小数点后面只能有0到2位小数
        this.money=newMoney
        if( newMoney.length>9){
          return this.money=newMoney.slice(0,9)
        }
},
    //关闭支付渠道ID: szfuatm、thaipayp2p、vnldpayqr102、vnldpaypbank105这两条通提示框
    tipsShow(){
      this.showModalTips = false;
    },
    // 入金须知展开收缩
    rjxzShowBtn(e){
      this.rjxzStatus = e;
    },
    checkboxState(e){
      this.tipsState = e.target.checked; 
    },
    submitTipsDepositData(){
      this.showModalTips = false;
      this.submitDepositFun();
    },
    submitDepositData(){
      if(this.currentPayment.type == "szfuatm" || this.currentPayment.type == "thaipayp2p" || this.currentPayment.type == "vnldpayqr102" || this.currentPayment.type == "vnldpaypbank105"){
        account.depositApplyTips({"payType":this.currentPayment.type}).then((res)=>{
          if(res.data.success == 1){
            this.showModalTips = true;
            this.upLoadState = false;
            this.tipsContent = res.data.value;
          }else{
            this.submitDepositFun();
            this.showModalTips = false;
            console.log(res.data.message);
          }              
        }).catch(err=>{
          console.log(err);
        });  
      }else{
        this.submitDepositFun();
      }   
    },
    // 信用卡支付通道期限显示月份列表
    getAllMonth(){
      this.monthState = !this.monthState;
      this.yearState = false;
    },
    selectMonth(val){
      this.monthVal = val;
      this.monthState = false;
      if(this.monthVal=="MM" || this.yearVal=="YY"){
        this.creditCardErr2 = true;
        this.creditCardErrContent2 = this.$i18n.t('accountCenter.creditCardText6');
      }else if(this.monthVal <= this.currentMonth && this.yearVal <= this.currentYear){
        this.creditCardErr2 = true;
        this.creditCardErrContent2 = this.$i18n.t('accountCenter.creditCardText6');
      }else{
        this.creditCardErr2 = false;
      }
    },
    // 信用卡支付通道期限显示年份列表
    getAllYear(){
      this.yearState = !this.yearState;
      this.monthState = false;
    },
    selectYear(val){
      this.yearVal = val;
      this.yearState = false;
      if(this.monthVal=="MM" || this.yearVal=="YY"){
        this.creditCardErr2 = true;
        this.creditCardErrContent2 = this.$i18n.t('accountCenter.creditCardText6');
      }else if(this.monthVal <= this.currentMonth && this.yearVal <= this.currentYear){
        this.creditCardErr2 = true;
        this.creditCardErrContent2 = this.$i18n.t('accountCenter.creditCardText6');
      }else{
        this.creditCardErr2 = false;
      }
    },
    yearInit(){
      var now = new Date();
      var nowYear = now.getFullYear();
      var month = now.getMonth() + 1;
      this.currentYear = nowYear;
      this.currentMonth = month;
      for (var i = nowYear;i <= nowYear+10; i++) {
          this.yearList.push(i)
      }
    },
    creditCardShow(){
      this.showModalCreditCard = false;
    },
    // 获取选中的国家及国家区码
     selectCountry(val){
      let countryCurrent = this.countryList.countrys.filter(it => it.countryId == val);
      this.countryCurrent = countryCurrent;
      this.countryList.countryActive = countryCurrent[0].countryId;
      console.info("countryCurrent",this.countryCurrent[0].countryId);
    },

    // 提交国家地区
    submitCountry(){
      if(!this.countryList.countryActive){
        this.errShowList.errShow = true;
        this.errShowList.errContent = this.$i18n.t('loginRegister.checkPrompt7'); 
        return;
      }else{
        this.errShowList.errShow = false;
      }
       let data = {
        countryId: this.countryCurrent[0].countryId,
      }
      account.updateCountry(data).then((res)=>{
        if(res.data.success == 1){
          this.showModalCountry = false;
          location.reload();
        }else{
          this.errShowList.errShow = true;
          this.errShowList.errContent = res.data.message; 
        }
      }).catch(err=>{
        this.errShowList.errShow = true;
        this.errShowList.errContent = err.message;
        console.log(err.message);
      })
    },
    emptyMoney(){
      this.money = '';
    },
    guideBtn(){
      if (profit.isClient){
        profit.goPage({title:this.$i18n.t('accountCenter.depositTite11'),url:this.depositGuideUrl},{
          success(){},
          fail(){}
        });
      }
    },
    selectPaymentSetting(item){
      this.money = item.money;
    },
    // 点击显示隐藏入金方式下拉
    goldChanneShow(){
      this.goldChanneState = true
      this.moreState = false
    },
    // 选中入金方式
    selectGoldChanne(channel){
      this.currentPayment = channel
      this.money = channel.inNum;
      this.inMoney = channel.inMinimum;
      this.paymentSettings = channel.activityList;
      this.selectState = channel.type;
      this.selectIndex = 1;
      if(channel.type == "szfucvs" ){
        if(!this.selectState2){
          this.selectState2 = 'IBONS'
        }
        this.szfucvsState = true;
      }else{
        this.selectState2 = '';
        this.szfucvsState = false;
      }
      
       console.log(channel)
       this.bankId=channel.bankId
       console.log(this.selectState+"8888888888")
       console.log(this.selectState2)
    },

    getBankvalue(item,items){
      this.currentBank = items;
      this.selectState = item.type
      this.selectState2 = items.value
      console.log(this.selectState)
      console.log(this.selectState2)
      // this.currentBank.name = item.name;
    },
    // 鼠标离开输入框校验不能为空
    inputBlur(type){
      if(type == 1){
        if(!this.creditCardNumber){
          this.creditCardErr1 = true;
          this.creditCardErrContent1 = this.$i18n.t('accountCenter.creditCardText3');                
        }else{
          this.creditCardErr1 = false;
        }
      }
      if(type == 2){
        if(!this.creditCardCcv){
          this.creditCardErr3 = true;
          this.creditCardErrContent3 = this.$i18n.t('accountCenter.creditCardText7');  
        }else{
          this.creditCardErr3 = false;
        }
      }
      if(type == 3){
        if(!this.creditCardName){
          this.creditCardErr4 = true;
          this.creditCardErrContent4 = this.$i18n.t('accountCenter.creditCardText11');
        }else{
          this.creditCardErr4 = false;
        }
      }
      if(type == 4){
        if(!this.creditCardSurName){
          this.creditCardErr5 = true;
          this.creditCardErrContent5 = this.$i18n.t('accountCenter.creditCardText12');
        }else{
            this.creditCardErr5 = false;
        }
      }
    },
    // 信用卡提交入金
    submitCreditCard(){
      if(!this.creditCardNumber){
        this.creditCardErr1 = true; 
        this.creditCardErrContent1 = this.$i18n.t('accountCenter.creditCardText3');
        return;               
      }else{
        this.creditCardErr1 = false;
      }
      if(this.monthVal=="MM" || this.yearVal=="YY"){
        this.creditCardErr1 = false;
        this.creditCardErr2 = true; 
        this.creditCardErrContent2 = this.$i18n.t('accountCenter.creditCardText6');
        return;
      }
      if(this.monthVal <= this.currentMonth && this.yearVal <= this.currentYear){
        this.creditCardErr1 = false;
        this.creditCardErr2 = true;
        this.creditCardErrContent2 = this.$i18n.t('accountCenter.creditCardText6');
        return;
      }else{
        this.creditCardErr2 = false;
      }
      if(!this.creditCardCcv){
        this.creditCardErr2 = false;
        this.creditCardErr3 = true;  
        this.creditCardErrContent3 = this.$i18n.t('accountCenter.creditCardText7'); 
        return;
      }else{
        this.creditCardErr3 = false;
      }
      if(!this.creditCardName){
        this.creditCardErr3 = false;
        this.creditCardErr4 = true;
        this.creditCardErrContent4 = this.$i18n.t('accountCenter.creditCardText11');
        return;
      }else{
        this.creditCardErr4 = false;
      }
      if(!this.creditCardSurName){
        this.creditCardErr4 = false;
        this.creditCardErr5 = true;
        this.creditCardErrContent5 = this.$i18n.t('accountCenter.creditCardText12');
        return;
      }else{
          this.creditCardErr5 = false;
      }
      this.upLoadState = true;
      // 信用卡提交入金接口
      account.creditCardDepositApplySubmit({
        amount: this.money,
        creditCardNumber: this.creditCardNumber,
        cvvCode: this.creditCardCcv,
        email: this.userEmail,
        expirationMonth: this.monthVal,
        expirationYear: this.yearVal,
        firstName: this.creditCardName,
        lastName: this.creditCardSurName,
        payType: this.currentPayment.type,
        requestSource: profit.isClient ? (profit.isAndroidApp ? 'android':'ios'):'uc'
      }).then(res => {
        if(res.data.success){
            this.upLoadState = false;
            if (profit.isClient){
              profit.appEvent({eventName:'cm_deposit_commit_2'},{
                success(){},
                fail(){}
              });
              profit.depositeApply({transId:res.data.value.transId},{
                success(){},
                fail(){}
              });
            }
          // alert(res.data.message);
          const title = this.$i18n.t('accountCenter.reminder');
          const cancel = this.$i18n.t('perfectData.cancel');
          const determine = this.$i18n.t('perfectData.determine');
          
		  if(this.currentPayment.type =="okpay" || this.currentPayment.type == "CaresPayVisa"|| this.currentPayment.type == "CaresPayMaster"){
        if(res.data.value.dataType=='json'){
          this.isShowConfirm = true
			    this.$refs.myConfirm.show(res.data.value.data, {
			      type: 'alert',
			      cancelText:cancel,
			      confirmText: determine,
			      titleText: title,
			      data: '我是外界传进来的数据'
			  })
        }else{
        var newWin;
			  if (profit.isClient){
				newWin = window.open('','_self');
			  }else {
				newWin = window.open('', 'enterpriseCust', "toolbar=yes,location=no,directories=no,status=no,menubar=no,scrollbars=no,titlebar=no,toolbar=no,resizable=no,copyhistory=yes, width=918, height=609,top=250,left=400");
			  }
			  newWin.document.body.innerHTML = res.data.value.data;
			  newWin.document.forms[0].submit();
     
        }
		  } else {
			  this.isShowConfirm = true
			  this.$refs.myConfirm.show(res.data.value.data, {
			      type: 'alert',
			      cancelText:cancel,
			      confirmText: determine,
			      titleText: title,
			      data: '我是外界传进来的数据'
			  })
		  }
          
        }else {
          // alert(res.data.message);
          this.upLoadState = false;
          const title = this.$i18n.t('accountCenter.reminder');
          const cancel = this.$i18n.t('perfectData.cancel');
          const determine = this.$i18n.t('perfectData.determine');
          this.isShowConfirm = true
          this.$refs.myConfirm.show(res.data.message, {
              type: 'alert',
              cancelText:cancel,
              confirmText: determine,
              titleText: title,
              data: '我是外界传进来的数据'
          })
        }
      }).catch(err => {
        console.log(err.message);
      })
    },
    //提交入金
    submitDepositFun(){
      console.log(this.currentPayment.type);
      if(this.currentPayment.type == "rapydpay" || this.currentPayment.type =="okpay" || this.currentPayment.type == "CaresPayVisa"|| this.currentPayment.type == "CaresPayMaster"){
        // 获取信用卡用户信息
        account.getQueryCreditCard().then((res)=>{
            if(res.data.success == 1){
                if(res.data.value.creditCardNumber){
                  this.creditCardNumber = res.data.value.creditCardNumber;
                }else{
                  this.creditCardNumber = '';
                }
                if(res.data.value.creditCardFirstName){
                  this.creditCardName = res.data.value.creditCardFirstName;
                }else if(res.data.value.firstName){
                  this.creditCardName = res.data.value.firstName;
                }else{
                  this.creditCardName = '';
                }
                if(res.data.value.creditCardLastName){
                  this.creditCardSurName = res.data.value.creditCardLastName;
                }else if(res.data.value.lastName){
                  this.creditCardSurName = res.data.value.lastName;
                }else{
                  this.creditCardSurName = '';
                }                                              
            }else{
              console.log(res.data.message); 
            }
        }).catch(err=>{
          console.log(err.message);
        });
        this.showModalCreditCard = true;
      }else{
        if(this.money < this.currentPayment.inMinimum || this.money > this.currentPayment.inMaximum){

          return;
        }
        this.upLoadState = true;
        account.depositApplySubmit({
          // accountNumber: this.userInfo.spNumber,
          amount: this.money,
          bankId: this.bankId ? this.bankId: this.currentBank.value,
          // bankId: this.currentBank.value ? this.currentBank.value : '',
          payType: this.currentPayment.type,
          activityRemark: this.activityRemark,
          nextNotRemind: this.tipsState,
          requestSource: profit.isClient ? (profit.isAndroidApp ? 'android':'ios'):'uc'
        }).then(res => {
          if (res.data.success){
                this.upLoadState = false;
                this.currentBank.value = null;
                if (profit.isClient){
                  profit.appEvent({eventName:'cm_deposit_commit_2'},{
                    success(){},
                    fail(){}
                  });
                  profit.depositeApply({transId:res.data.value.transId},{
                    success(){},
                    fail(){}
                  });
                }
              switch (res.data.value.dataType) {
                case "form":
                  // 根据马甲包类型添加跳转页面的参数appId
                  const appId = this.$route.query.appId;
                  switch(appId) {
                      case "com.exchange6.app":
                      case "com.fnsadw.ios":
                          const form = convert.xml2js(res.data.value.data);
                          const params = form.elements[0];
                          var data = {};
                          params.elements.forEach(param =>{
                            data[param.attributes.name] = param.attributes.value;
                            data["appId"] = appId;
                          });                       
                          break;
                      default:                        
                  } 
                  
                  if(this.currentPayment.type == 'szfucvs'){
                      console.log('szfucvs88888')
                      const form = convert.xml2js(res.data.value.data);
                      const params = form.elements[0];
                      var data = {};
                      params.elements.forEach(param =>{
                        data[param.attributes.name] = param.attributes.value;
                      });   
                      this.$router.push({
                        path: 'onlineBank',
                        query: data
                      });
                  }else if(this.currentPayment.type == 'szfuatm' ){
                    console.log('szfucvs99999')
                    const form = convert.xml2js(res.data.value.data);
                    const params = form.elements[0];
                    var data = {};
                    params.elements.forEach(param =>{
                      data[param.attributes.name] = param.attributes.value;
                    });   
                    this.$router.push({
                      path: 'onlineBank',
                      query: data
                    });
                  }
                  else if(this.currentPayment.type == 'szfucvsfamilys'){
                    const form = convert.xml2js(res.data.value.data);
                    const params = form.elements[0];
                    var data = {};
                    params.elements.forEach(param =>{
                      data[param.attributes.name] = param.attributes.value;
                    });   
                    this.$router.push({
                      path: 'onlineBank',
                      query: data
                    });
                  }
                  else if(this.currentPayment.type == 'szfucvshilife'){
                    const form = convert.xml2js(res.data.value.data);
                    const params = form.elements[0];
                    var data = {};
                    params.elements.forEach(param =>{
                      data[param.attributes.name] = param.attributes.value;
                    });   
                    this.$router.push({
                      path: 'onlineBank',
                      query: data
                    });
                  }
                  else if(this.currentPayment.type == 'szfucvsibons'){
                    const form = convert.xml2js(res.data.value.data);
                    const params = form.elements[0];
                    var data = {};
                    params.elements.forEach(param =>{
                      data[param.attributes.name] = param.attributes.value;
                    });   
                    this.$router.push({
                      path: 'onlineBank',
                      query: data
                    });
                  }
                  else if(this.currentPayment.type == 'szfucvsokmart'){
                    const form = convert.xml2js(res.data.value.data);
                    const params = form.elements[0];
                    var data = {};
                    params.elements.forEach(param =>{
                      data[param.attributes.name] = param.attributes.value;
                    });   
                    this.$router.push({
                      path: 'onlineBank',
                      query: data
                    });
                  } 
                  else{
                    console.log('其它100000')
                      var newWin;
                      if (profit.isClient){
                        newWin = window.open('','_self');
                      }else {
                        newWin = window.open('', 'enterpriseCust', "toolbar=yes,location=no,directories=no,status=no,menubar=no,scrollbars=no,titlebar=no,toolbar=no,resizable=no,copyhistory=yes, width=918, height=609,top=250,left=400");
                      }
                      newWin.document.body.innerHTML = res.data.value.data;
                      newWin.document.forms[0].submit();
                  }
                  break;              
                case "url":
                  if (profit.isClient) {
                    window.open(res.data.value.data, '_self');
                  } else {
                    window.open(res.data.value.data, 'enterpriseCust', "toolbar=yes,location=no,directories=no,status=no,menubar=no,scrollbars=no,titlebar=no,toolbar=no,resizable=no,copyhistory=yes, width=918, height=609,top=250,left=400");
                  }
                  break;
              }
            
          }else {
            // alert(res.data.message);
            this.upLoadState = false;
            const title = this.$i18n.t('accountCenter.reminder');
            const cancel = this.$i18n.t('perfectData.cancel');
            const determine = this.$i18n.t('perfectData.determine');
            this.isShowConfirm = true
            this.$refs.myConfirm.show(res.data.message, {
                type: 'alert',
                cancelText:cancel,
                confirmText: determine,
                titleText: title,
                data: '我是外界传进来的数据'
            })
          }
        })
      }  
    },

    // 提示框组件传递取消和确定事件,根据对应的事件类型跳转或关闭提示框
    userBehaviorFun(type){
      console.log(type);
      const that = this;
      if(type === "clickCancel"){
        // that.$router.push("./");//去账户首页
        this.isShowConfirm = false    
      }else if(type === "clickConfirm"){
        // that.$router.push("./");
        this.isShowConfirm = false;
        this.showModalCreditCard = false; 
        location.reload();   
      }          
    }, 
    // 判断手机端还是PC端
    _isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    },
    dateSelectShow(){
      this.monthState = false;
      this.yearState = false;
    }
  },
  mounted () {
    document.addEventListener('click', this.dateSelectShow)
  }
};
</script>
<style>
@import url("../assets/css/public.css");
</style>
