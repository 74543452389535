<template>
  <div class="hide-pc">
    <div class="account-nav">
      <router-link :to="'/deposit'">{{$t('accountCenter.deposit')}}</router-link>
      <router-link :to="'/withdraw'">{{$t('accountCenter.withdraw')}}</router-link>
      <router-link :to="'/capitalFlow'">{{$t('accountCenter.capitalFlow')}}</router-link>
      <router-link :to="'/tradeHistory'">{{$t('accountCenter.tradeQuery')}}</router-link>
    </div>
  </div>
  
</template>

<script>


export default{
  props: {
    
  },
  data() {
    return {
      
    }
  },
  created() {
    
  },
  methods: {
    
  }
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .account-nav{width: 100%;min-width: 200px;background: #f7f7f7;border-radius: 10px; display: flex;flex-direction: row;justify-content: space-between;align-items: center;}
  .account-nav a{width: 20%; padding: 15px 25px; height: 100%;font-size: 20px;color: #949494;text-align: center;}
  .account-nav a:first-child{border-top-left-radius: 10px;border-bottom-left-radius: 10px;}
  .account-nav a:last-child{border-top-right-radius: 10px;border-bottom-right-radius: 10px;}
  .account-nav a.router-link-active{background: #ffd94c;color: #000;}
</style>
